import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../../models";
import { Logo, Background } from '../../../components';

type RuleNames = 'front' | 'container' | 'title' | 'subtitle' | 'background';
interface IProps {
    title: string;
    subtitle: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    front: {
        position: 'absolute',
        //background: 'radial-gradient(circle, rgba(62,62,62,1) 0%, rgba(46,46,51,1) 31%, rgba(2,0,36,1) 89%) no-repeat border-box',
        zIndex: 10,
        transform: 'translate3d(0,0,0.1px)',
        textAlign: 'center',
        overflow: 'hidden',
        //borderRadius: '5%'
    },
    background: {
    },
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        textAlign: 'center',
        transform: 'translate(-50%,-25%)',
        zIndex: 10,
        width: '50%',
    },
    title: {
        color: '#fff',
        //border: '1px solid #FC7100',
        width: 'fit-content',
        margin: '0 auto',
        padding: 0,
        fontSize: 16
    },
    subtitle: {
        color: '#fff',
        width: 'fit-content',
        margin: '0 auto',
        padding: 0,
        fontSize: 30
    }
})

const Front = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (<div className={classes.front}>
        <div className={classes.background}>
            <Background />
        </div>
        <div className={classes.container}>
            <Logo scale={1} />
        </div>

    </div>);
}

export default Front;