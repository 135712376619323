import React from 'react';
import { Card } from './components';
import './App.css';

function App() {
  return (
    <div className="App">
      <Card
        name='MURESAN TIMOTEI EMANUEL'
        title={`TIMMY'S FILMS`}
        contacts={[
          {
            label: "cell.",
            link: "tel:+393475742551",
            text: "+39 347 574 2551"
          },
          {
            label: "",
            link: "mailto:muresantimoteiemanuel@yahoo.com",
            text: "muresantimoteiemanuel@yahoo.com"
          },
          {
            label: "instagram:",
            link: "https://www.instagram.com/timmyro91",
            text: "@timmyro91"
          }
        ]}
      />
    </div>
  );
}

export default App;
