import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";

type RuleNames = 'root';
interface IProps {
    scale?: number;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
    }
})

const Separator = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (<svg viewBox="0 0 30 300" xmlns="<http://www.w3.org/2000/svg>" transform={`scale(${props.scale ?? 1})`}>
        <defs>
            <filter id="reflection" x={0} y={4}>
                <feGaussianBlur in="SourceGraphic" stdDeviation="200" />
            </filter>
            <radialGradient id="Gradient" cx="50%" cy="80%" r="80%" fx="50%" fy="100%">
                <stop offset="0%" stop-color="white" />
                <stop offset="55%" stop-color="black" />
            </radialGradient>
            <mask id="mask-reflection">
                <path d="M -4 8 C 2 4, 22 4, 28 8 Z" fill="url(#Gradient)" />
            </mask>
        </defs>
        <polygon style={{ fill: "orange" }} points="5,100 5,120 23,110" />
        <polygon style={{ fill: "gray" }} points="14,85 15,85 15,100 14,100" />
        <polygon style={{ fill: "gray" }} points="14,120 15,120 15,200 14,200" />

    </svg >);
}

export default Separator;