import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";

type RuleNames = 'root';
interface IProps {
    scale?: number;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
    }
})

const Logo = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (<svg viewBox="0 0 24 8" xmlns="<http://www.w3.org/2000/svg>" transform={`scale(${props.scale ?? 1})`}>
        <defs>
            <filter id="reflection" x={0} y={4}>
                <feGaussianBlur in="SourceGraphic" stdDeviation="200" />
            </filter>
            <radialGradient id="Gradient" cx="50%" cy="80%" r="80%" fx="50%" fy="100%">
                <stop offset="0%" stop-color="white" />
                <stop offset="55%" stop-color="black" />
            </radialGradient>
            <mask id="mask-reflection">
                <path d="M -4 8 C 2 4, 22 4, 28 8 Z" fill="url(#Gradient)" />
            </mask>
        </defs>
        <g>
            <text x="0" y="1" textLength={20} fill="white" fontSize={1}>MURESAN TIMOTEI EMANUEL</text>
            <text x="0" y={3.5} textLength={20} fill="white" fontSize={3}>TIMMY'S <tspan fill="orange">FILMS</tspan></text>
            <polygon style={{ fill: "orange" }} points="21,0.5 21,3.5 23.5,2" />
        </g>

        {/* <path d="M -4 8 C 2 4, 22 4, 28 8 Z" fill="url(#Gradient)" /> */}
        <g width={24} height={4} transform="scale(1,-1) translate(0,-11.15)" mask="url(#mask-reflection)">
            <text x="0" y={5} textLength={20} fill="white" fontSize={1}>MURESAN TIMOTEI EMANUEL</text>
            <text x="0" y={7.5} textLength={20} fill="white" fontSize={3}>TIMMY'S <tspan fill="orange">FILMS</tspan></text>
            <polygon style={{ fill: "orange" }} points="21,4.5 21,7.5 23.5,6" />
        </g>
    </svg >);
}

export default Logo;