import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";

type RuleNames = 'root';
interface IProps {
    scale?: number;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
    }
})

const Background = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (<svg viewBox="0 0 50 30" xmlns="<http://www.w3.org/2000/svg>" transform={`scale(${props.scale ?? 1})`}>
        <defs>
            <filter id="filter">
                <feGaussianBlur in="SourceGraphic" stdDeviation="0.5" />
            </filter>
            <radialGradient id="gradient1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" style={{ stopColor: "#3e3e3e" }} />
                <stop offset="100%" style={{ stopColor: "#020024" }} />
            </radialGradient>
        </defs>
        <rect x={0} y={0} width={50} height={30} fill="url(#gradient1)" />
        <path d="M -25 24 C -25 14, 75 14, 75 24 L 75 30 L -25 30 Z" filter="url(#filter)" fill='#010014' />
    </svg >);
}

export default Background;