import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../../models";
import { Logo, Background, Separator } from '../../';


type RuleNames = 'back' | 'name' | 'subtitle' | 'contacts' | 'contact' | 'contactLabel' | 'left' | 'middle' | 'right' | 'background';
interface IContact {
    label: string,
    link: string,
    text: string
}
interface IProps {
    title: string,
    subtitle: string,
    contacts: IContact[]
}
//background: '#FC7100',
const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    back: {
        position: 'absolute',
        // background: '#313131',
        zIndex: 10,
        transform: 'translate3d(0,0,0px) rotateY(180deg)',
        padding: 0,
        color: '#313131',
        '& a': {
            color: '#313131',
            textDecoration: 'unset',
        },
        display: 'inline-block',
        verticalAlign: 'top',
        fontSize: 0,
        overflow: 'hidden',
        //borderRadius: '5%'
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0
    },
    left: {
        width: '32%',
        fontSize: 'initial',
        display: 'inline-block',
        position: 'relative',
        height: '100%',
        verticalAlign: 'top',
        "& > svg": {
            position: 'absolute',
            top: 165,
            left: 0,
            transform: 'translateY(-50%)',
            padding: 15
        }
    },
    middle: {
        width: '6%',
        fontSize: 'initial',
        display: 'inline-block',
        position: 'relative',
        height: '100%',
        verticalAlign: 'top',
    },
    right: {
        width: '62%',
        fontSize: 'initial',
        display: 'inline-block',
        position: 'relative',
        height: '100%',
        verticalAlign: 'top',
    },
    name: {
        fontWeight: 'bold',
        fontSize: 18,
        margin: [5, 0]
    },
    subtitle: {
        color: '#fff',
        fontSize: 14,
        margin: [5, 0]
    },
    contacts: {
        position: 'absolute',
        top: "50%",
        left: -15,
        padding: 10,
        transform: "translateY(-50%)",
        textAlign: 'left',
        color: '#fff'
    },
    contact: {
        margin: 0,
        "& a": {
            color: '#fff'
        }
    },
    contactLabel: {
        fontWeight: 'bold',
        color: '#fff',
        display: 'inline-block',
        width: 80
    },
})

const Template = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.back}>
            <div className={classes.background}>
                <Background />
            </div>
            <div className={classes.left}>
                <Logo />
            </div>
            <div className={classes.middle}>
                <Separator />
            </div>
            <div className={classes.right}>
                <div className={classes.contacts}>
                    <p className={classes.contact}>cell. <a href="tel:+393475742551">+39 347 574 2551</a></p>
                    <p className={classes.contact}><a href="mailto:muresantimoteiemanuel@yahoo.it">muresantimoteiemanuel@yahoo.it</a></p>
                    <p className={classes.contact}>instagram: <a target="_blank" href="https://www.instagram.com/timmyro91">@timmyro91</a></p>

                </div>
            </div>

        </div>
    );
}

export default Template;